.share_page {

}

.share_page .save {
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-self: center;
}

.share_page .message {
  margin-top: 2em;
}

.share_page .url {
  margin: 2em;
  color: #999999;
}

.share_page .image_wrap {
  position: relative;
  min-height: 15em;
  margin-top: 1em;
  margin-bottom: 2em;
}

.share_page .info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
}

.share_page .info > div {
  display: inline-flex;
  flex-direction: column;
  background-color: #fff;
  opacity: 0.8;
  align-items: center;
  border-radius: 0.5em;
}
